import upsell from "../upsell"

export default (getView) => (nextStepPath) => ({
  ...getView(nextStepPath),
  ...upsell("shopping_list_and_premium"),

  init() {
    this.commonInit()
  },

  onInit() {
    this.initUpsell()
  },
})
