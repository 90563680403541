import sortBy from "lodash/sortBy"

const PATHS = Object.entries({
  premium: ["premium-offer", "premium-checkout", "premium-thank-you"],
  shopping_list: ["shopping-list-offer", "shopping-list-checkout", "shopping-list-thank-you"],
  shopping_list_and_premium: ["bundle-offer", "bundle-checkout", "bundle-thank-you"],
  chat: ["chat-offer", "chat-checkout", "chat-last-chance", "chat-thank-you"],
}).reduce(
  (paths, [type, rawPaths]) => ({
    ...paths,
    [type]: rawPaths.map(
      (rawPath) =>
        `${pipelineConfig.pathPrefix}${
          pipelineConfig.variants[pipelineConfig.variant].pagePaths[rawPath]
        }/`,
    ),
  }),
  {},
)

export default (upsellOfferType) => ({
  initUpsell() {
    if (upsellOfferType && this.isOfferPath()) {
      this.store.clearPrimerSession()
    }

    this.store.values.skippedUpsellOffers = this.store.values.skippedUpsellOffers.filter(
      (type) => type !== upsellOfferType,
    )

    const upsell = pipelineConfig.offers.find(
      (offer) => offer.type && offer.type.toLowerCase() === upsellOfferType,
    )

    if (!upsell) return

    this.store.values.activeOffer = upsell
  },

  isOfferPath() {
    return window.location.pathname === PATHS[upsellOfferType][0]
  },

  getThankYouPath() {
    if (upsellOfferType === "chat") {
      return PATHS[upsellOfferType][3]
    }

    return PATHS[upsellOfferType][2]
  },

  getFailedCheckoutPath() {
    const failedPaymentsPaths = {
      premium: "premium-checkout-fail",
      shopping_list: "shopping-list-checkout-fail",
      shopping_list_and_premium: "bundle-checkout-fail",
      chat: "chat-checkout-fail",
    }
    const failedPath = failedPaymentsPaths[upsellOfferType]

    return `${pipelineConfig.pathPrefix}${
      pipelineConfig.variants[pipelineConfig.variant].pagePaths[failedPath]
    }/`
  },

  getNextUpsellOfferPath() {
    const upsellOffers = sortBy(
      pipelineConfig.offers.filter((offer) => offer.type && offer.type.toLowerCase() !== "basic"),
      "customData.sequence",
    )

    if (!upsellOfferType) return PATHS[upsellOffers[0].type.toLowerCase()][0]

    const currentUpsellOfferIndex = upsellOffers.findIndex(
      (upsellOffer) => upsellOffer.type.toLowerCase() === upsellOfferType,
    )

    const nextUpsellOffer = upsellOffers[currentUpsellOfferIndex + 1]

    let type
    if (nextUpsellOffer?.type.toLowerCase() === "shopping_list_and_premium") {
      if (
        this.store.values.skippedUpsellOffers.includes("premium") &&
        this.store.values.skippedUpsellOffers.includes("shopping_list")
      ) {
        type = nextUpsellOffer.type.toLowerCase()
      } else {
        type = upsellOffers[currentUpsellOfferIndex + 2]?.type.toLowerCase()
      }
    } else {
      type = nextUpsellOffer?.type.toLowerCase()
    }

    if (type) {
      return PATHS[type][0]
    } else {
      return `${pipelineConfig.pathPrefix}${
        pipelineConfig.variants[pipelineConfig.variant].pagePaths["send-off"]
      }`
    }
  },

  async goToNextUpsellOffer(skip = false) {
    if (skip && upsellOfferType) {
      const skippedOffer = pipelineConfig.offers.find(
        (offer) => offer.type && offer.type.toLowerCase() === upsellOfferType,
      )

      this.store.values = {
        ...this.store.values,
        skippedUpsellOffers: [...this.store.values.skippedUpsellOffers, upsellOfferType],
      }

      if (skippedOffer) await this.store.push(false, skippedOffer.brandOfferId)
    }

    window.location = `${this.getNextUpsellOfferPath()}#stored-token=${this.store.values.token}`
  },

  goToThankYou(response) {
    let url = PATHS[upsellOfferType][2]
    if (upsellOfferType === "chat") {
      url = PATHS[upsellOfferType][3]
    }
    if (response) {
      url = `${url}?${this.store
        .buildUrlParamsForThankYou(response)
        .toString()}#stored-token=${this.store.values.token}`
    }
    window.location = url
  },

  goToPaymodes() {
    window.location = `${PATHS[upsellOfferType][1]}#stored-token=${this.store.values.token}`
  },

  async purchase() {
    this.loading = true

    const response = await this.store.payOrCreateSession()
    await this.store.trackViewAndMaybeStatus("INITIATE_CHECKOUT")

    if (response.instantlyCharged) {
      this.goToThankYou(response)
    } else {
      this.goToPaymodes()
    }
  },
})
