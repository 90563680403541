import upsell from "../upsell"

const DEFAULT_NUTRITIONIST = "elina"
const NO_NUTRIONIST = "no_coach"

export default (getView) => (nextStepPath) => ({
  ...getView(nextStepPath),
  ...upsell("chat"),

  activeNutrionist: DEFAULT_NUTRITIONIST,

  saveSelectedNutrionistInStore(nutrionist) {
    this.store.values.selectedChatNutrionist = nutrionist
  },

  init() {
    this.saveSelectedNutrionistInStore(DEFAULT_NUTRITIONIST)
    this.commonInit()
  },

  handleNutrionistChange(nutrionist) {
    this.activeNutrionist = nutrionist
    this.saveSelectedNutrionistInStore(nutrionist)
  },

  async handlePurchase(isModal) {
    if (isModal) {
      this.saveSelectedNutrionistInStore(DEFAULT_NUTRITIONIST)
      this.purchase()

      return
    }

    // user chose no nutrionist, open modal screen
    if (this.activeNutrionist === NO_NUTRIONIST) {
      const nextStepLink = `${pipelineConfig.pathPrefix}${pipelineConfig.variants[pipelineConfig.variant].pagePaths["chat-last-chance"]}#stored-token=${this.store.values.token}`
      window.location = nextStepLink

      return
    }

    this.purchase()
  },

  async handleDecline(isModal) {
    if (!isModal) {
      const nextStepLink = `${pipelineConfig.pathPrefix}${pipelineConfig.variants[pipelineConfig.variant].pagePaths["chat-last-chance"]}#stored-token=${this.store.values.token}`
      window.location = nextStepLink

      return
    }

    this.goToNextUpsellOffer(true)
  },

  onInit() {
    this.initUpsell()
  },
})
