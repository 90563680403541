import lottie from "lottie-web"
import basicThankYouAnimation from "../../animations/thank-you-animation.json"
import upsell from "../upsell"

export default (getView) => (nextStepPath) => ({
  ...getView(nextStepPath),
  ...upsell(),

  statusToTrack: "PURCHASE",

  init() {
    this.commonInit()

    lottie.loadAnimation({
      container: document.querySelector("#thankyou-animation"),
      animationData: basicThankYouAnimation,
      renderer: "svg",
      loop: true,
      autoplay: true,
    })
  },

  onInit() {
    this.store.savePurchasedBasicOffer()
  },
})
