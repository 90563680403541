import { getGolaPaceKgValues, getGolaPaceLbsValues } from "../helpers"

export default (getView) =>
  ({ nextStepPath, formFields }) => ({
    ...getView({
      nextStepPath,
      formFields,
    }),

    isGeneratingPlan: true,

    init() {
      this.handleLoadingEnd = this.handleLoadingEnd.bind(this)
      this.commonInit()

      if (this.formValues.email) {
        this.isGeneratingPlan = false
      }

      this.store.values.marketingAgreed = false
    },

    // Product requirement for the formula:
    // Use maximum goal pace (2kg | 4.5lbs per week)
    getFirstWeekValue() {
      const maxGoalPace = this.isMetric()
        ? getGolaPaceKgValues().reverse()[0]
        : getGolaPaceLbsValues().reverse()[0]

      return Math.round(maxGoalPace)
    },

    handleSubmit() {
      window.analytics.track(window.getEventName("Continue Clicked"))

      this.store.values.marketingAgreed = true
      this.submit({ push: true, withToken: true })
    },

    handleLoadingEnd() {
      setTimeout(() => {
        this.isGeneratingPlan = false
      }, 2000)
    },
  })
