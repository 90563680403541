import Alpine from "alpinejs"
import "./polyfills"
import Bugsnag from "@bugsnag/js"
import dataStore from "./dataStore"
import translation from "./translation"
import components from "./components"
import prefetchNextPage from "./preload"

if (
  !window.location.host.includes("localhost") &&
  !window.location.host.includes("host.docker.internal")
) {
  Bugsnag.start({ apiKey: "a383d74fed09040a8180a6388ef025f7" })
}

document.addEventListener("DOMContentLoaded", function () {
  let sid = localStorage.getItem("fraud_net_session_id") ?? generateRandomString()

  localStorage.setItem("fraud_net_session_id", sid)

  const fraudNetSettings = document.createElement("script")
  fraudNetSettings.type = "application/json"
  fraudNetSettings.setAttribute("fncls", "fnparams-dede7cc5-15fd-4c75-a9f4-36c430ee3a99")
  fraudNetSettings.text = JSON.stringify({
    f: sid,
    s: pipelineConfig.brandCode + "_" + window.getPage(),
  })

  document.body.appendChild(fraudNetSettings)
  const fraudNetScript = document.createElement("script")
  fraudNetScript.src = "https://c.paypal.com/da/r/fb.js"
  document.body.appendChild(fraudNetScript)

  const body = document.querySelector("body")

  // Add a CSS class to trigger animation
  window.requestAnimationFrame(() => {
    body.classList.add("pipe-dom-content-loaded")
  })

  const svgs = document.querySelectorAll("svg[data-pipe-svg-animation]")

  svgs.forEach((svg) => {
    window.requestAnimationFrame(() => {
      svg.classList.add("pipe-svg-loaded")
    })
  })
})

window.Alpine = Alpine

components.forEach(({ name, data }) => {
  Alpine.data(name, data)
})

function getLocalStorageToken() {
  const data = dataStore.readFromLocalStorage()

  if (!data || !data.values) return

  return data.values.token
}

async function fetchDataFromApi() {
  const hashParams = new URLSearchParams(window.location.hash.substr(1))
  const token = hashParams.get("token") || hashParams.get("stored-token")
  const localStorageToken = getLocalStorageToken()

  if (token && localStorageToken !== token) {
    dataStore.values = { token }
    await dataStore.pull(false, true)
  }
}

fetchDataFromApi().then(async () => {
  Alpine.store("data", dataStore)
  Alpine.start()
  translation.onInit()
  prefetchNextPage()
})

window.getCookieValue = function (name) {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) return parts.pop().split(";").shift()

  return null
}

function generateRandomString(length = 32) {
  return Array.from({ length }, () =>
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789".charAt(
      Math.floor(Math.random() * 62),
    ),
  ).join("")
}

window.getPage = function () {
  return window.location.href
    .split("?")[0]
    .split("#")[0]
    .split("/")
    .filter((segment) => segment)
    .pop()
}

window.getPageName = function (key) {
  return window.pagePathNames[key] ?? key
}

window.getEventName = function (key) {
  return window.segmentEventKeys[key] ?? key
}
