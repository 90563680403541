import waitForElement from "./waitForElement"

const PRIMER_LOCALES = [
  "de",
  "el",
  "en",
  "es",
  "fr",
  "pl",
  "pt",
  "bg",
  "hu",
  "ro",
  "ru",
  "sk",
  "nl",
]

export default () => ({
  primerLoading: true,
  submitting: false,

  paymentMethod: null,
  selectedPaymentMethodType: null,

  error: null,
  initError: null,

  // Checks for the compability with locale
  isPrimerLocale(locale) {
    return PRIMER_LOCALES.includes(locale || "en")
  },

  async initPrimer({ checkoutCompletePath, checkoutFailedPath }) {
    function observeElementInsertion(selector) {
      if (!window.MutationObserver) {
        console.error("MutationObserver is not supported in this browser.")
        return
      }

      // Callback function for when a mutation occurs
      const observerCallback = (mutationsList, observer) => {
        for (const mutation of mutationsList) {
          if (mutation.type === "childList") {
            // Check if the element now exists in the DOM
            const element = document.querySelector(selector)
            if (element) {
              // Once found log event to segment and disconnect observer
              window.analytics.track(window.getEventName("Primer Checkout Loaded Confirmation"))
              observer.disconnect()
              break
            }
          }
        }
      }

      // Create a MutationObserver with the callback function
      const observer = new MutationObserver(observerCallback)

      // Start observing the document's body for added nodes
      observer.observe(document.body, { childList: true, subtree: true })
    }

    const primerCheckoutCardNumberId = "#primer-checkout-card-number-field"
    observeElementInsertion(primerCheckoutCardNumberId)

    if (document.getElementById("primer-checkout-submit-button-container")) {
      return window.location.reload()
    }

    this.primerLoading = true

    await waitForElement("#primer-submit-button")

    const submitButton = document.getElementById("primer-submit-button")

    let clientSession, checkout

    try {
      clientSession = await this.store.payOrCreateSession(true)

      checkout = await this.showUniversalCheckout({
        checkoutCompletePath,
        checkoutFailedPath,
        clientSession,
        submitButton,
      })
    } catch (error) {
      console.error(error)
      // Handle Uncaught (in promise) Error: The provided `clientToken` has expired.
      try {
        clientSession = await this.store.payOrCreateSession(true)
        checkout = await this.showUniversalCheckout({
          checkoutCompletePath,
          checkoutFailedPath,
          clientSession,
          submitButton,
        })
      } catch (error) {
        this.initError = true
      }
    }

    this.primerLoading = false

    submitButton.classList.remove("hidden")
    submitButton.addEventListener(
      "click",
      () => {
        checkout.submit()
        this.trackInvalidFormSubmission()
      },
      false,
    )

    this.patchTranslations(checkout)
  },

  async showUniversalCheckout({
    checkoutCompletePath,
    checkoutFailedPath,
    clientSession,
    submitButton,
  }) {
    // TODO: Handle error
    if (!clientSession) {
      this.initError = true
      return
    }

    if (clientSession.instantlyCharged) {
      window.location = `${checkoutCompletePath}#stored-token=${this.store.values.token}`
      return
    }

    const { clientToken } = clientSession

    // Trigger analytics event and if fails so moves on
    try {
      await window.analytics.track(window.getEventName("Primer Checkout Requested"))
    } catch (e) {
      console.log("Segment: Primer Checkout Requested failed", e)
    }

    const checkout = await Primer.showUniversalCheckout(clientToken, {
      container: "#primer-checkout-container",
      locale: pipelineConfig.language || "en",
      vault: {
        visible: false,
      },
      successScreen: false,
      errorMessage: {
        disabled: true,
        onErrorMessageShow: (message) => {
          // console.log('onErrorMessageShow', message)
          // this.setError()
          // this.scrollToPrimer()
        },
        onErrorMessageHide: (message) => {
          // console.log('onErrorMessageHide', message)
        },
      },
      submitButton: {
        useBuiltInButton: false,
        onVisible: (isVisible) => {
          // console.log({isVisible})
          submitButton.classList.remove("hidden")
        },
        onDisable: (isDisabled) => {
          // console.log({isDisabled})
          submitButton.disabled = isDisabled
          this.listenExitIntent = !isDisabled
        },
        onLoading: (isLoading) => {
          // console.log({isLoading})
          submitButton.classList.remove("hidden")
          submitButton.classList.add("loading")
        },
      },
      card: {
        cardholderName: {
          placeholder: primerTranslations.name_placeholder,
        },
        cardNumber: {
          placeholder: "1234 1234 1234 1234",
        },
        expiryDate: {
          placeholder: primerTranslations.expiry_placeholder,
        },
        cvv: {
          placeholder: "●●●",
        },
      },
      paypal: {
        buttonColor: "gold",
        buttonShape: "rect",
        paymentFlow: "PREFER_VAULT",
      },
      googlePay: {
        captureBillingAddress: true,
      },
      applePay: {
        captureBillingAddress: true,
      },
      style: {
        fontFaces: [
          {
            fontFamily: "Acumin Pro",
            src: `url("${window.origin}${pipelineConfig.pathPrefix}fonts/AcuminPro-Book.woff2") format("woff2")`,
          },
        ],
        input: {
          base: {
            fontFamily: "'Acumin Pro', Arial, sans-serif",
            fontSize: "16px",
            lineHeight: "1.25",
            color: "#212121",
            background: "#fff",
            borderColor: "#f0f0f0",
            borderRadius: "3px",
            paddingHorizontal: 12,
            height: 36 + 2, // primer uses -2
            placeholder: {
              color: "#868c8e",
            },
          },
        },
      },
      form: {
        inputLabelsVisible: false,
      },

      onBeforePaymentCreate: ({ paymentMethodType }, handler) => {
        this.paymentMethod = paymentMethodType

        try {
          this.selectedPaymentMethodType = paymentMethodType
          const eventKey = ("payment-method-selected-" + paymentMethodType).toLowerCase()

          window.analytics.track(window.segmentEventKeys[eventKey] ?? eventKey)
        } catch (error) {
          console.error("Error in onBeforePaymentCreate:", error)
        } finally {
          handler.continuePaymentCreation()
        }
      },

      onPaymentCreationStart: () => {
        this.store.trackViewAndMaybeStatus("SUBMIT_CC_INPUT")
      },

      onCheckoutComplete: ({ payment }) => {
        this.submitting = false

        let url = checkoutCompletePath
        if (clientSession) {
          const clientSessionWithPrimer = {
            ...clientSession,
            providerId: payment.id,
          }
          url = `${checkoutCompletePath}?${this.store
            .buildUrlParamsForThankYou(clientSessionWithPrimer)
            .toString()}#stored-token=${this.store.values.token}`
        }

        this.store.clearPrimerSession()

        try {
          window.analytics.track(window.getEventName("Primer Responded Success"))
        } catch (error) {
          console.error("Analytics tracking failed:", error)
        } finally {
          window.location = url
        }
      },

      onCheckoutFail: (error, { payment }, handler) => {
        this.submitting = false
        this.listenExitIntent = true

        const searchParams = new URLSearchParams(window.location.search)
        searchParams.set("primer_error", this.paymentMethod)

        window.analytics.track(window.getEventName("Primer Responded Fail"))

        if (!handler) {
          window.history.replaceState(
            {},
            "",
            `${checkoutFailedPath}?${searchParams}#stored-token=${this.store.values.token}`,
          )
          window.location.reload()
          return
        }

        this.store.trackViewAndMaybeStatus("FAILED_CHECKOUT")
        handler.showErrorMessage()
        window.history.replaceState(
          {},
          "",
          `${checkoutFailedPath}?${searchParams}#stored-token=${this.store.values.token}`,
        )
        window.location.reload()
      },
    })

    window.analytics.track(window.getEventName("Primer Checkout Loaded"))

    this.store.trackViewAndMaybeStatus("INITIATE_CHECKOUT")

    return checkout
  },

  applyCustomLabel(id, text) {
    const currentField = window.document.getElementById(id)

    if (!currentField) return

    // Create our label
    const newLabel = window.document.createElement("div")
    newLabel.dataset.testid = "CreditCardForm.inputLabel"
    newLabel.classList.add("PrimerCheckout__label")
    newLabel.innerHTML = text

    // Display our label
    currentField.prepend(newLabel)
  },

  applyCustomErrorMessage(id, text) {
    const currentField = document.getElementById(id)
    if (!currentField) return

    const targetNode = currentField.getElementsByTagName("div")[0]
    if (!targetNode) return

    // Mutation config
    const config = { childList: true }

    // Callback function executes on dom changes
    const callback = (mutationList) => {
      for (const mutation of mutationList) {
        if (mutation.type === "childList") {
          // Apply our custom translation
          if (targetNode.innerHTML !== text && targetNode.innerHTML !== "") {
            targetNode.innerHTML = text
          }
        }
      }
    }
    // Create/Observe the node mutations
    const observer = new MutationObserver(callback)
    observer.observe(targetNode, config)
  },

  // Patch the primer translations with ours
  patchTranslations(checkout) {
    this.applyCustomLabel("primer-checkout-card-number-field", primerTranslations.card_number)
    this.applyCustomLabel("primer-checkout-card-expirydate-field", primerTranslations.expiry)
    this.applyCustomLabel("primer-checkout-card-cvv-field", primerTranslations.cvv)
    this.applyCustomLabel("primer-checkout-cardholder-name-field", primerTranslations.name)

    if (!this.isPrimerLocale(pipelineConfig.language)) {
      this.applyCustomErrorMessage(
        "primer-checkout-card-number",
        primerTranslations.errors.empty_field,
      )
      this.applyCustomErrorMessage(
        "primer-checkout-card-expirydate-error",
        primerTranslations.errors.empty_field,
      )
      this.applyCustomErrorMessage(
        "primer-checkout-card-cvv-error",
        primerTranslations.errors.empty_field,
      )
      this.applyCustomErrorMessage(
        "primer-checkout-cardholder-name-error",
        primerTranslations.errors.empty_field,
      )
    }
  },

  setError() {
    this.error = primerTranslations.errors[this.paymentMethod]
  },

  // Primer Checkout options - config, styles, buttons, texts etc.
  getPrimerOptions(primerCheckout, store) {
    const paymentHost = `${pipelineConfig.productApiUrl}`
    const markPaymentAsFailed = this.markPaymentAsFailed.bind(this)

    const options = {
      container: "#checkout-container",
      locale: pipelineConfig.language || "en",
      vault: {
        visible: false,
      },
      successScreen: false,
      errorMessage: false,
      async onCheckoutComplete({ payment }) {
        window.location = `${paymentHost}/pay/primer/post-payment/${primerCheckout.invoice.id}`
      },

      async onCheckoutFail(error, { payment }) {
        await markPaymentAsFailed()
        window.location = `${paymentHost}/pay/primer/post-payment/${primerCheckout.invoice.id}`
      },

      submitButton: {
        useBuiltInButton: this.isPrimerLocale(pipelineConfig.language),
        onVisible: (isVisible) => {
          const submitButton = window.document.getElementById("submit-button")
          if (!submitButton) return

          if (isVisible) {
            submitButton.style.display = "flex"
          } else {
            submitButton.style.display = "none"
          }
        },
        onDisable: (isDisabled) => {
          const submitButton = window.document.getElementById("submit-button")
          if (!submitButton) return

          if (isDisabled) {
            submitButton.disabled = true
          } else {
            submitButton.disabled = false
          }
        },
        onLoading: (isLoading) => {
          const submitButton = window.document.getElementById("submit-button")
          if (!submitButton) return

          if (isLoading) {
            submitButton.getElementsByClassName("button-loader")[0].style.display = "block"
          } else {
            submitButton.getElementsByClassName("button-loader")[0].style.display = "none"
          }
        },
      },
      card: {
        cardholderName: {
          placeholder: "",
        },
      },
      paypal: {
        buttonColor: "gold",
        buttonShape: "rect",
        paymentFlow: "PREFER_VAULT",
      },
      googlePay: {
        captureBillingAddress: true,
      },
      applePay: {
        captureBillingAddress: true,
      },
      style: {
        formSpacings: {
          betweenLabelAndInput: "12px",
          betweenInputs: "16px",
        },
        inputLabel: {
          fontFamily: "Avenir, Arial, sans-serif",
          fontSize: "12px",
          lineHeight: "normal",
          fontWeight: "normal",
          color: "#212121",
        },
        input: {
          base: {
            background: "#fff",
            color: "#35383C",
          },
        },
        inputErrorText: {
          fontSize: "14px",
          fontWeight: "400",
        },
        submitButton: {
          base: {
            fontFamily: "JosefinSans, sans-serif",
            color: "#fff",
            background: "#46A477",
            fontSize: "20px",
            fontWeight: "bold",
            borderRadius: "6px",
          },
        },
      },
    }

    return options
  },

  trackInvalidFormSubmission() {
    let isEventTracked = false

    const mutationHandler = (mutationsList, observer) => {
      for (const mutation of mutationsList) {
        if (mutation.type === "childList" && mutation.addedNodes.length > 0) {
          mutation.addedNodes.forEach((node) => {
            const element = node.nodeType === Node.ELEMENT_NODE ? node : node.parentElement
            if (
              element &&
              element.classList &&
              element.classList.contains("PrimerCheckout__helperText--error")
            ) {
              if (!isEventTracked) {
                window.analytics.track(window.getEventName("Invalid Form Submitted"))
                isEventTracked = true

                observer.disconnect()
              }
            }
          })
        }
      }
    }

    const observer = new MutationObserver(mutationHandler)

    const checkoutContainer = document.getElementById("primer-checkout")
    if (checkoutContainer) {
      observer.observe(checkoutContainer, {
        childList: true,
        subtree: true,
      })
    }
  },
})
