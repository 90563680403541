export default (popupStepPath) => ({
  initExitIntentPopup() {
  
    if (!this.store.shouldShowExitIntentPopup()) return

    const handleExitIntent = async (e) => {
      if (e.clientY < 0 && !this.store.values.exitIntent) {
        window.removeEventListener("mouseout", handleExitIntent)
        const tokenPath = this.store.values.token
          ? `${popupStepPath}#stored-token=${this.store.values.token}`
          : popupStepPath
        window.location.replace(tokenPath)
      }
    }

    window.addEventListener("mouseout", handleExitIntent)
  },

  async closePopup() {
    this.store.values.showDiscountModal = false
    this.store.values.exitIntent = true
    await this.store.push()
    window.scrollTo(0, 0)
  },
})
