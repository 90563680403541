const PAYMODES = {
  paypal_recurring: 3,
  stripe: 13,
  stripe_redirect: 13,
  primer: 48,
  primer_redirect: 48,
}

const getPipelineID = () => {
  const url = pipelineConfig.orderApiUrl
  const pipelinesMatchedString = url.match(/pipelines\/+\d+\//) // atrod 'pipelines/71/'

  return pipelinesMatchedString[0].split("/")[1] // atrod 'pipelines , 71' un tu paņem 71
}

const getPrimerMetadata = () => {
  try {
    return pipelineConfig.primerMetadata
  } catch (error) {}

  return undefined
}

const getGA4SessionId = () => {
  try {
    const cookies = document.cookie
      .split("; ")
      .find((item) => item.includes("_ga_"))
      .split("=")[1]
    return cookies.split(".")[2]
  } catch (e) {
    console.error(`GA4 session ID not found`)
  }
  return undefined
}

const createCheckout = async ({ paymode }) => {
  const store = Alpine.store("data")

  const offerId = store.values.activeOffer.brandOfferId
  const userId = store.values.userId

  const paymodeId = PAYMODES[paymode]
  const urlPrefix = `${window.location.origin}${pipelineConfig.pathPrefix}`
  const successUrl = `${urlPrefix}${pipelineConfig.pagePaths["basic-thank-you"]}`

  const failUrlCheckout = `${urlPrefix}${pipelineConfig.pagePaths["basic-checkout-fail"]}?error=${paymode}`
  const failUrlAB = `${urlPrefix}${pipelineConfig.pagePaths["abandoned-basket-checkout-fail"]}?error=${paymode}`
  const isAB =
    window.location.pathname.includes(
      pipelineConfig.pagePaths["abandoned-basket-plan-selection"],
    ) || window.location.pathname.includes(pipelineConfig.pagePaths["abandoned-basket-checkout"])
  const failUrl = isAB ? failUrlAB : failUrlCheckout

  const res = await fetch(
    `${pipelineConfig.productApiUrl}/pay/checkout/${offerId}/${userId}/${paymodeId}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        successful_payment_path: successUrl,
        failed_payment_path: failUrl,
        chance: false,
        origin_id: store.values.id,
        origin_host: `https://${store.values.domain}`,
        origin_path: pipelineConfig.pathPrefix,
        utm_source: store.values.utm_source || "",
        utm_medium: store.values.utm_medium || "",
        utm_campaign: store.values.utm_campaign || "",
        utm_term: store.values.utm_term || "",
        utm_content: store.values.utm_content || "",
        initiated_remotely: true,
        external_sync: true,
        token: store.values.token,
        ga_cid: store.values._ga?.match(/[^\.]+\.[^\.]+$/)?.[0],
        pipelineId: getPipelineID(),
        ga_session_id: getGA4SessionId(),
        fbc: store.values._fbc,
        fbp: store.values._fbp,
        fraud_net_session_id: store.values.fraud_net_session_id,
        fb_ads_id: store.values.fb_ads_id || undefined,
        fb_adset_id: store.values.fb_adset_id || undefined,
        segmentAnonymousId: store.values.ajs_anonymous_id || "",
        primer_pipeline_metadata: {
          ...getPrimerMetadata(),
        },
      }),
    },
  )

  return res.json()
}

// Creates Primer session from our BE api-diet
const createRedirectCheckout = async (paymode) => {
  const store = Alpine.store("data")

  const checkout = await createCheckout({
    paymode,
  })

  store.values.checkout = checkout
  store.values.invoiceIdentifier = checkout.invoice.identifier
  store.values.initiateCheckoutAt = new Date().toISOString()

  window.location = checkout.url
}

const createPrimerCheckout = async () => {
  const store = Alpine.store("data")

  const paymode = "primer"

  const checkout = await createCheckout({
    paymode,
  })

  store.values.checkout = checkout
  store.values.invoiceIdentifier = checkout.invoice.identifier
  store.values.initiateCheckoutAt = new Date().toISOString()
  store.values.preloaded = true

  return checkout
}

export { createCheckout, createRedirectCheckout, createPrimerCheckout }
