import upsell from "../upsell"

export default (getView) =>
  ({ nextStepPath, upsellOfferType }) => ({
    ...getView(nextStepPath),
    ...upsell(upsellOfferType),

    statusToTrack: "PURCHASE",

    init() {
      this.commonInit()
    },

    onInit() {
      this.initUpsell()
    },
  })
