function xhrPrefetch(url) {
  const req = new XMLHttpRequest()
  req.open(`GET`, url)
  req.send()
}

function supportsPreload() {
  const link = document.createElement("link")
  return (link.relList || {}).supports && link.relList.supports("prefetch")
}

export default function prefetchNextPage() {
  if (supportsPreload()) return

  document.querySelectorAll('[rel="prefetch"]').forEach(function (link) {
    xhrPrefetch(link.getAttribute("href"))
  })
}
