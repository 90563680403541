import upsell from "../upsell"
import primer from "../primer"

export default (getView) => (nextStepPath) => ({
  ...getView(nextStepPath),
  ...upsell("shopping_list"),
  ...primer(),

  onInit() {
    this.initUpsell()
    this.initPrimer({
      checkoutCompletePath: this.getThankYouPath(),
      checkoutFailedPath: this.getFailedCheckoutPath(),
    })
  },
})
